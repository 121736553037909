import React, { FC } from "react"

import * as styles from "./badge.module.scss"

export interface IBadge {
  label: string
  onClick?: () => void
}

const Badge: FC<IBadge> = ({ label, ...rest }) => (
  <span className={styles.component} {...rest}>
    {label}
  </span>
)

export default Badge
