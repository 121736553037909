const stripTrailingSlash = (url: string) => url.replace(/\/$/, "")

export const sanitize = (url: string) => stripTrailingSlash(url)

export const getApiUrlContent = () => sanitize(process.env.GATSBY_API_URL_CONTENT as string)

export const getApiUrlForm = () => sanitize(process.env.GATSBY_API_URL_FORM as string)

export const getGoogleMapApiKey = () => process.env.GATSBY_GOOGLE_MAPS_API_KEY as string

export enum Environment {
  Production = "production",
  Development = "development",
}

export const getEnvironment = (): Environment => process.env.NODE_ENV as Environment
