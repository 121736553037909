import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { createSelector } from "reselect"

import { IMediaItem } from "../../components/cards/media-card"
import { ISelectItem } from "../../components/select"
import { IMediaFilters } from "../../services/medias.props"
import { MediaRecord } from "../medias/medias.types"
import { IGlobalState as GS } from "../state.props"
import { SortField, SortOrder } from "./pageMedias.reducer"

const basePath = "pageMedias"

export const getAllMediaItems = (state: GS): MediaRecord => get(state, `${basePath}.allMedia`, {})

export const areMediasImported = (state: GS): boolean => !isEmpty(getAllMediaItems(state))

export const getVisibleMediaItems = (state: GS): Record<string, IMediaItem> => {
  return get(state, `${basePath}.visibleMedia`, {})
}

export const getVisibleMediaItemsAsList = createSelector<GS, MediaRecord, IMediaItem[]>(getVisibleMediaItems, (items) =>
  Object.values(items)
)

export const getSelectedAuthor = (state: GS): ISelectItem | undefined => get(state, `${basePath}.selectedAuthor`)

export const getSelectedChapter = (state: GS): ISelectItem | undefined => get(state, `${basePath}.selectedChapter`)

export const getSelectedModule = (state: GS): ISelectItem | undefined => get(state, `${basePath}.selectedModule`)

export const getSelectedSortField = (state: GS): SortField => get(state, `${basePath}.selectedSortField`)

export const getSelectedSortOrder = (state: GS): SortOrder => get(state, `${basePath}.selectedSortOrder`)

export const getNextPage = (state: GS): number => get(state, `${basePath}.nextPage`, 1)

export const getAreMediasPending = (state: GS): boolean => get(state, `${basePath}.areMediasPending`, false)

export const getSelectedTagIds = (state: GS): string[] => get(state, `${basePath}.selectedTagIds`)

export const getMediaFilters = (isLoadMore?: boolean) =>
  createSelector<
    GS,
    ISelectItem | undefined,
    ISelectItem | undefined,
    ISelectItem | undefined,
    SortField,
    SortOrder,
    number,
    string[],
    IMediaFilters
  >(
    getSelectedAuthor,
    getSelectedChapter,
    getSelectedModule,
    getSelectedSortField,
    getSelectedSortOrder,
    getNextPage,
    getSelectedTagIds,
    (author, chapterType, module, sortField, sortOrder, nextPage, tagIds) => ({
      authorId: get(author, "id"),
      chapterTypeId: get(chapterType, "id"),
      moduleId: get(module, "id"),
      page: isLoadMore ? nextPage : 0,
      sortField,
      sortOrder,
      tagIds,
    })
  )
