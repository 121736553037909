import get from "lodash/get"

import { ISelectItem } from "../components/select"
import { ITag } from "../components/tag-cloud"
import { getRelatedChapters } from "./common.selectors"

const basePath = "allModule"

export const getAllModuleNodes = (data: any): any[] => get(data, `${basePath}.nodes`, [])

export const getAllModulesAsTags = (data: any, newModuleLabel = "Neues Modul"): ITag[] => {
  const tags = getAllModuleNodes(data).map((moduleNode) => ({
    id: moduleNode.id,
    title: moduleNode.content.title,
    isSelected: false,
  }))

  return [
    ...tags,
    {
      id: "module__new",
      title: newModuleLabel,
      isSelected: false,
    },
  ]
}

/**
 * Get list of uuid describing the reference of chapter <> module
 */
export const getAllModuleChapterItems = (data: any): ISelectItem[] =>
  getAllModuleNodes(data).reduce((list, moduleNode) => {
    const moduleId = get(moduleNode, "id")
    const moduleHasChapters = getRelatedChapters(moduleNode)
    return [
      ...list,
      ...moduleHasChapters.map((chapter) => ({
        id: chapter.id,
        moduleId,
        chapterTitle: chapter.title,
      })),
    ]
  }, [])
