import { AnyAction } from "redux"

import { IImageProps } from "../../components/image/image.props"
import { getAllAuthorItems } from "../../graphql/allAuthor.selectors"
import {
  IMPORT_ALL_AUTHORS,
  SET_SELECTED_AUTHOR_STATE,
  SET_SELECTED_AUTHOR_TYPE,
  SET_SELECTED_NATIONWIDE_COOP,
} from "./pageAuthors.actions"

export type AuthorType = "person" | "organization"

export interface IAuthorItem {
  city?: string
  id: string
  image: IImageProps
  jobTitle: string
  nationwideCooperation: boolean
  state?: string
  slug: string
  title: string
  type: AuthorType
}

export interface IAuthorsPageState {
  authors: IAuthorItem[]
  selectedAuthorType: AuthorType | null
  selectedAuthorState: string | null
  selectedNationwideCoop: boolean
}

export const initialState: IAuthorsPageState = {
  authors: [],
  selectedAuthorType: null,
  selectedAuthorState: null,
  selectedNationwideCoop: false,
}

export default (state: IAuthorsPageState = initialState, action: AnyAction): IAuthorsPageState => {
  switch (action.type) {
    case IMPORT_ALL_AUTHORS:
      return {
        ...state,
        authors: getAllAuthorItems(action.payload.graphqlData),
      }
    case SET_SELECTED_AUTHOR_TYPE:
      return {
        ...state,
        selectedAuthorType: action.payload.authorType,
      }
    case SET_SELECTED_AUTHOR_STATE:
      return {
        ...state,
        selectedAuthorState: action.payload.authorState,
      }
    case SET_SELECTED_NATIONWIDE_COOP:
      return {
        ...state,
        selectedNationwideCoop: action.payload.selectedNationwideCoop,
      }
  }
  return state
}
