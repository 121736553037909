import { ImportAllMediaActionCreator } from "../page-medias/pageMedias.types"

const BASE = `MEDIAS`
export const IMPORT_ALL_MEDIA = `${BASE}.IMPORT_ALL_MEDIA`

export const importAllMedia: ImportAllMediaActionCreator = (graphqlData) => ({
  payload: {
    graphqlData,
  },
  type: IMPORT_ALL_MEDIA,
})
