import { ActionCreator, AnyAction } from "redux"

import { loadMedias } from "../../services/medias"
import { AnyActionCreator } from "../media-collection/media-collection.actions"
import { ThunkResult } from "../state.props"
import { getMediaFilters } from "./pageMedias.selectors"
import {
  FetchMediasErrorActionCreator,
  FetchMediasSuccessActionCreator,
  ImportAllMediaActionCreator,
  SelectItemActionCreator,
  ToggleTagActionCreator,
} from "./pageMedias.types"

const BASE = `PAGE_MEDIAS`
export const IMPORT_ALL_MEDIA = `${BASE}.IMPORT_ALL_MEDIA`
export const LOAD_MORE_MEDIAS = `${BASE}.LOAD_MORE_MEDIAS`
export const SELECT_AUTHOR = `${BASE}.SELECT_AUTHOR`
export const SELECT_CHAPTER = `${BASE}.SELECT_CHAPTER`
export const SELECT_MODULE = `${BASE}.SELECT_MODULE`
export const SELECT_SORT_FIELD = `${BASE}.SELECT_SORT_FIELD`
export const SELECT_SORT_ORDER = `${BASE}.SELECT_SORT_ORDER`
export const TOGGLE_TAG = `${BASE}.TOGGLE_TAG`
export const SET_SINGLE_TAG = `${BASE}.SET_SINGLE_TAG`
export const UNSET_TAGS = `${BASE}.UNSET_TAGS`
export const FETCH_MEDIAS_PENDING = `${BASE}.FETCH_MEDIAS_PRENDING`
export const FETCH_MEDIAS_SUCCESS = `${BASE}.FETCH_MEDIAS_SUCCESS`
export const FETCH_MEDIAS_ERRROR = `${BASE}.FETCH_MEDIAS_ERROR`

export const importAllMedia: ImportAllMediaActionCreator = (graphqlData) => ({
  payload: {
    graphqlData,
  },
  type: IMPORT_ALL_MEDIA,
})

export const loadMoreMedias: AnyActionCreator = () => ({
  type: LOAD_MORE_MEDIAS,
})

export const selectAuthor: SelectItemActionCreator = (item) => ({
  payload: {
    item,
  },
  type: SELECT_AUTHOR,
})

export const selectChapter: SelectItemActionCreator = (item) => ({
  payload: {
    item,
  },
  type: SELECT_CHAPTER,
})

export const selectModule: SelectItemActionCreator = (item) => ({
  payload: {
    item,
  },
  type: SELECT_MODULE,
})

export const selectSortField: SelectItemActionCreator = (item) => ({
  payload: {
    item,
  },
  type: SELECT_SORT_FIELD,
})

export const selectSortOrder: SelectItemActionCreator = (item) => ({
  payload: {
    item,
  },
  type: SELECT_SORT_ORDER,
})

export const toggleTag: ToggleTagActionCreator = (tag) => ({
  payload: {
    tag,
  },
  type: TOGGLE_TAG,
})

export const setSingleTag: ToggleTagActionCreator = (tag) => ({
  payload: {
    tag,
  },
  type: SET_SINGLE_TAG,
})

export const fetchMediasPending: AnyActionCreator = () => ({
  type: FETCH_MEDIAS_PENDING,
})

export const fetchMediasSuccess: FetchMediasSuccessActionCreator = (payload) => ({
  payload,
  type: FETCH_MEDIAS_SUCCESS,
})

export const fetchMediasError: FetchMediasErrorActionCreator = (error) => ({
  payload: { error },
  type: FETCH_MEDIAS_ERRROR,
})

export const fetchMedias =
  (isLoadMore?: boolean): ThunkResult<void> =>
  async (dispatch, getState) => {
    dispatch(fetchMediasPending())
    try {
      const filters = getMediaFilters(isLoadMore)(getState())
      const result = await loadMedias(filters, isLoadMore ? true : false)
      dispatch(fetchMediasSuccess(result))
    } catch (error) {
      // TODO: report to Sentry
      dispatch(fetchMediasError(error))
    }
  }

/**
 * Sets the state for the respective filter and fetches new mediaIds
 * from the server afterwards.
 */
export const filterMedias = (filterAction: ActionCreator<AnyAction>, isLoadMore?: boolean): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(filterAction())
    dispatch(fetchMedias(isLoadMore))
  }
}

/**
 * Same as above, but callable directly
 */
export const unsetTagsAction = (): ThunkResult<void> => {
  return (dispatch) => {
    dispatch({
      type: UNSET_TAGS,
    })
    dispatch(fetchMedias(false))
  }
}

/**
 * Provides a more readable api to create generic filter actions:
 *
 * instead of:
 * toggleAction={tag => filterMedias(() => toggleTag(tag))}
 *
 * you can write:
 * toggleAction={mediaFilterAction(toggleTag)}
 */
export const mediaFilterAction =
  (actionCreator: (...args: any[]) => AnyAction, isLoadMore?: boolean) =>
  (...actionCreatorArgs: any[]): any =>
    filterMedias(() => actionCreator(...actionCreatorArgs), isLoadMore)
