import compose from "lodash/fp/compose"
import { AnyAction, Reducer } from "redux"

import { IMediaItem } from "../../components/cards/media-card"
import * as allMediaSelectors from "../../graphql/allMedia.selectors"
import { getMediaItemList } from "../../graphql/common.selectors"
import { basePath } from "../../graphql/corePageMedias.selectors"
import { IFetchMediaResult } from "../../services/medias.props"
import { arrToObj } from "../../utils/object-utils"
import { MediaRecord } from "../medias/medias.types"
import {
  FETCH_MEDIAS_ERRROR,
  FETCH_MEDIAS_PENDING,
  FETCH_MEDIAS_SUCCESS,
  IMPORT_ALL_MEDIA,
  SELECT_AUTHOR,
  SELECT_CHAPTER,
  SELECT_MODULE,
  SELECT_SORT_FIELD,
  SELECT_SORT_ORDER,
  SET_SINGLE_TAG,
  TOGGLE_TAG,
  UNSET_TAGS,
} from "./pageMedias.actions"

const getInitialMedias = (data: any) => getMediaItemList(data, basePath)

export type SortField = "date" | "title"

export type SortOrder = "asc" | "desc"

export interface IMediasPageState {
  allMedia: MediaRecord
  areMediasPending: boolean
  nextPage: number
  selectedAuthor: string | null
  selectedChapter: string | null
  selectedModule: string | null
  selectedSortField: SortField
  selectedSortOrder: SortOrder
  selectedTagIds: string[]
  visibleMedia: MediaRecord
}

const initialState: IMediasPageState = {
  allMedia: {},
  areMediasPending: false,
  nextPage: 1,
  selectedAuthor: null,
  selectedChapter: null,
  selectedModule: null,
  selectedSortField: "date",
  selectedSortOrder: "asc",
  selectedTagIds: [],
  visibleMedia: {},
}

const addTag = (list: string[], tagId: string) => [...list, tagId]

const removeTag = (list: string[], tag: string) => list.filter((id) => id !== tag)

const toggleTag = (list: string[], tag: string) => (list.includes(tag) ? removeTag(list, tag) : addTag(list, tag))

const extractMediaIds = (items: IMediaItem[]) => items.map((item) => item.id)

const mapIdsToMediaItems =
  (allMedias: MediaRecord) =>
  (ids: string[]): MediaRecord =>
    ids.reduce((map, id) => {
      const item = allMedias[id]
      return item ? { ...map, [id]: item } : map // Don't safe undefined values from the list, in case the allMedia Map doesn't include one of the initial media items for some reason.
    }, {})

/**
 * Returns the `allMedia` and `visibleMedia` fields, which are derived
 * from the given graphql data.
 */
const importMedia = (graphqlData: any) => {
  const allMediaList = allMediaSelectors.getAllMediaItems(graphqlData) // TODO: should already return a Map
  const allMedia = arrToObj<IMediaItem>(allMediaList, (media) => media.id)
  const getInitialMediaFromAllMedia = compose(mapIdsToMediaItems(allMedia), extractMediaIds, getInitialMedias)
  const visibleMedia = getInitialMediaFromAllMedia(graphqlData)

  return {
    allMedia,
    visibleMedia,
  }
}

const getVisibleMedia = (state: IMediasPageState, result: IFetchMediaResult) => {
  const fetchedMedia = mapIdsToMediaItems(state.allMedia)(result.mediaIds)
  if (!result.isLoadMore) {
    // filter action -> always show fetched items
    return fetchedMedia
  }

  // load more action -> show visible + fetched items
  return {
    ...state.visibleMedia,
    ...fetchedMedia,
  }
}

const resetPage = () => ({ nextPage: 1 })

const reducer: Reducer = (state: IMediasPageState = initialState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_MEDIAS_PENDING:
      return {
        ...state,
        areMediasPending: true,
      }
    case FETCH_MEDIAS_SUCCESS:
      return {
        ...state,
        areMediasPending: false,
        nextPage: action.payload.nextPage || 0,
        visibleMedia: getVisibleMedia(state, action.payload),
      }
    case FETCH_MEDIAS_ERRROR:
      return {
        ...state,
        areMediasPending: false,
      }
    case IMPORT_ALL_MEDIA:
      return {
        ...state,
        ...importMedia(action.payload.graphqlData),
      }
    case SELECT_AUTHOR:
      return {
        ...state,
        ...resetPage(),
        selectedAuthor: action.payload.item,
      }
    case SELECT_CHAPTER:
      return {
        ...state,
        ...resetPage(),
        selectedChapter: action.payload.item,
      }
    case SELECT_MODULE:
      return {
        ...state,
        ...resetPage(),
        selectedModule: action.payload.item,
      }
    case SELECT_SORT_FIELD:
      // Apply initial SortOrder of SortField (#2345)
      return {
        ...state,
        selectedSortField: action.payload.item,
        selectedSortOrder: action.payload.item === "date" ? "desc" : "asc",
      }
    case SELECT_SORT_ORDER:
      return {
        ...state,
        selectedSortOrder: action.payload.item,
      }
    case SET_SINGLE_TAG:
      return {
        ...state,
        ...resetPage(),
        selectedTagIds: [action.payload.tag.id],
      }
    case TOGGLE_TAG:
      return {
        ...state,
        ...resetPage(),
        selectedTagIds: toggleTag(state.selectedTagIds, action.payload.tag.id),
      }
    case UNSET_TAGS:
      return {
        ...state,
        ...resetPage(),
        selectedTagIds: [],
      }
  }
  return state
}

export default reducer
