import IconArrowDown from "../components/svg-icon/svg-icon-arrow-down"
import IconArrowUp from "../components/svg-icon/svg-icon-arrow-up"
import { IMediasView } from "../templates/medias/medias.view.props"
import {
  getAuthorSelectItemList,
  getContentItem,
  getMediaItemList,
  getRelatedChapterTypeItems,
  getRelatedModuleSelectList,
  getRelatedTags,
  getSeoProps,
  getSlug,
} from "./common.selectors"

export const basePath = "corePageMedias"

export const getMediasSlug = (data: any) => getSlug(data, basePath)

export const getMailtoBody = (data: any) => getContentItem(data, `shareMailtoBody`, basePath)

export const getMailtoSubject = (data: any) => getContentItem(data, `shareMailtoSubject`, basePath)

export default (data: any): IMediasView => ({
  authorItems: getAuthorSelectItemList(data, basePath),
  chapterItems: getRelatedChapterTypeItems(data, basePath),
  contributeButton: getContentItem(data, `contributeButton`, basePath),
  contributeTitle: getContentItem(data, `contributeTitle`, basePath),
  description: getContentItem(data, `description`, basePath),
  filtersTitle: getContentItem(data, `filterTitle`, basePath),
  medias: getMediaItemList(data, basePath),
  moduleItems: getRelatedModuleSelectList(data, basePath),
  resetTagsLabel: getContentItem(data, `resetTagsLabel`, basePath),
  seo: getSeoProps(data, basePath),
  sortButtons: [
    { label: "A-Z", value: "title" },
    { label: "Neueste", value: "date" },
  ],
  sortOrderButtons: [
    { label: IconArrowDown, value: "asc", srLabel: "Absteigend sortieren" },
    { label: IconArrowUp, value: "desc", srLabel: "Aufsteigend sortieren" },
  ],
  sortTitle: "Sortieren",
  tags: getRelatedTags(data, basePath),
  tagsTitle: getContentItem(data, `tagsTitle`, basePath),
  title: getContentItem(data, `title`, basePath),
})
