// import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { IPrivacyPolicyPopup } from "../../components/privacy-policy-popup/privacy-policy-popup.props"
import { importPrivacyPolicies } from "./privacyPolicies.actions"

export const useImportPrivacyPolicies = (data: IPrivacyPolicyPopup[], arePrivacyPoliciesImported: boolean = false) => {
  const dispatch = useDispatch()

  if (!arePrivacyPoliciesImported) {
    dispatch(
      importPrivacyPolicies(
        data.map((policy) => ({
          ...policy,
          text: policy.text.replace(/"/g, "'"),
        }))
      )
    )
  }
}
