import { AnyAction } from "redux"

import { ITag } from "../../components/tag-cloud"
import { ImportAllMediaActionCreator } from "../page-medias/pageMedias.types"

const BASE = `PAGE_OFFERS`
export const IMPORT_ALL_OFFERS = `${BASE}.IMPORT_ALL_OFFERS`
export const SET_SELECTED_OFFER_CATEGORY_ID = `${BASE}.SET_SELECTED_OFFER_CATEGORY_ID`
export const SET_SELECTED_OFFER_STATE = `${BASE}.SET_SELECTED_OFFER_STATE`
export const SET_SELECTED_PERMANENT = `${BASE}.SET_SELECTED_PERMANENT`

interface IToggleCategoryAction extends AnyAction {
  payload: {
    offerCategoryId: string
  }
}

type ToggleCategoryActionCreator = (tag: ITag) => IToggleCategoryAction

export const setTagAsSelectedOfferCategoryId: ToggleCategoryActionCreator = (tag) => ({
  payload: {
    offerCategoryId: tag.id,
  },
  type: SET_SELECTED_OFFER_CATEGORY_ID,
})

export const importAllOffers: ImportAllMediaActionCreator = (graphqlData: any) => ({
  payload: {
    graphqlData,
  },
  type: IMPORT_ALL_OFFERS,
})

export const setSelectedOfferCategoryId = (offerCategoryId: string) => ({
  payload: {
    offerCategoryId,
  },
  type: SET_SELECTED_OFFER_CATEGORY_ID,
})

export const setSelectedOfferState = (offerState: string | null) => ({
  payload: {
    offerState,
  },
  type: SET_SELECTED_OFFER_STATE,
})

export const setSelectedPermanent = (isSelected: boolean) => ({
  payload: {
    isSelected,
  },
  type: SET_SELECTED_PERMANENT,
})
