import { ActionCreator, AnyAction } from "redux"

import { loadMedias } from "../../services/medias"
import { AnyActionCreator } from "../media-collection/media-collection.actions"
import { getAllMediaItems } from "../medias/medias.selectors"
import { FetchMediasErrorActionCreator, FetchResolvedMediasSuccessActionCreator } from "../page-medias/pageMedias.types"
import { ThunkResult } from "../state.props"
import { mapIdsToMediaItems } from "./pageStart.reducer"
import { getMediaFilters } from "./pageStart.selectors"
import { ImportVisibleMediaActionCreator } from "./pageState.types"

const BASE = `PAGE_START`

export const IMPORT_VISIBLE_MEDIA = `${BASE}.IMPORT_VISIBLE_MEDIA`
export const LOAD_MORE_MEDIAS = `${BASE}.LOAD_MORE_MEDIAS`
export const FETCH_MEDIAS_PENDING = `${BASE}.FETCH_MEDIAS_PENDING`
export const FETCH_MEDIAS_SUCCESS = `${BASE}.FETCH_MEDIAS_SUCCESS`
export const FETCH_MEDIAS_ERROR = `${BASE}.FETCH_MEDIAS_ERROR`

export const importVisibleMedia: ImportVisibleMediaActionCreator = (allMedia, graphqlData) => ({
  payload: {
    allMedia,
    graphqlData,
  },
  type: IMPORT_VISIBLE_MEDIA,
})

export const loadMoreMedias: AnyActionCreator = () => ({
  type: LOAD_MORE_MEDIAS,
})

export const fetchMediasPending: AnyActionCreator = () => ({
  type: FETCH_MEDIAS_PENDING,
})

export const fetchMediasSuccess: FetchResolvedMediasSuccessActionCreator = (payload) => ({
  payload,
  type: FETCH_MEDIAS_SUCCESS,
})

export const fetchMediasError: FetchMediasErrorActionCreator = (error) => ({
  payload: { error },
  type: FETCH_MEDIAS_ERROR,
})

export const fetchMedias = (): ThunkResult<void> => async (dispatch, getState) => {
  dispatch(fetchMediasPending())
  try {
    const state = getState()
    const filters = getMediaFilters(state)
    const result = await loadMedias(filters, true)
    const allMedias = getAllMediaItems(state)
    dispatch(
      fetchMediasSuccess({
        isLoadMore: true,
        mediaItems: mapIdsToMediaItems(allMedias)(result.mediaIds),
        nextPage: result.nextPage,
      })
    )
  } catch (error) {
    // TODO: report to Sentry
    dispatch(fetchMediasError(error))
  }
}

/**
 * Sets the state for the respective filter and fetches new mediaIds
 * from the server afterwards.
 */
export const filterMedias = (filterAction: ActionCreator<AnyAction>): ThunkResult<void> => {
  return (dispatch) => {
    dispatch(filterAction())
    dispatch(fetchMedias())
  }
}

/**
 * Provides a more readable api to create generic filter actions:
 *
 * instead of:
 * toggleAction={tag => filterMedias(() => toggleTag(tag))}
 *
 * you can write:
 * toggleAction={mediaFilterAction(toggleTag)}
 */
export const mediaFilterAction =
  (actionCreator: (...args: any[]) => AnyAction) =>
  (...actionCreatorArgs: any[]): any =>
    filterMedias(() => actionCreator(...actionCreatorArgs))
