import { AnyAction } from "redux"

import { HIDE_SIDEBAR, SHOW_SIDEBAR, TOGGLE_SIDEBAR } from "./layout.actions"
import { ILayoutState } from "./layout.props"

const initialState: ILayoutState = {
  sidebarItem: null,
}

export default (state: ILayoutState = initialState, action: AnyAction): ILayoutState => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarItem: state.sidebarItem === action.payload.item ? null : action.payload.item,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        sidebarItem: action.payload.item,
      }
    case HIDE_SIDEBAR: {
      return {
        ...state,
        sidebarItem: null,
      }
    }
    default:
      return state
  }
}
