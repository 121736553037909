import { isEmpty } from "lodash/fp"

import { IMediaItem } from "../../components/cards"
import { IGlobalState as GS } from "../state.props"
import { ISavedMediaCollectionItem } from "./saved-media-collections.types"

export const selectSavedMediaCollections = (state: GS): ISavedMediaCollectionItem[] =>
  state?.savedMediaCollections?.collections ?? []

export const getSelectedSavedMediaCollection = (state: GS): ISavedMediaCollectionItem | null =>
  state?.savedMediaCollections.selectedCollection

export const getAllMedias = (state: GS): any => state?.savedMediaCollections.allMedias

export const getMediaById = (state: GS, id: string): any =>
  getAllMedias(state).find((media: IMediaItem) => media.id === id)

export const areSavedMediaCollectionsMediasImported = (state: GS): boolean => !isEmpty(getAllMedias(state))
