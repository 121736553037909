import get from "lodash/get"

import { IMediaItem } from "../../components/cards/media-card"
import { IMediaFilters } from "../../services/medias.props"
import { IGlobalState as GS } from "../state.props"

const basePath = "pageStart"

export const getVisibleStartMedias = (state: GS): IMediaItem[] => {
  const map: Record<string, IMediaItem> = get(state, `${basePath}.visibleMedias`, {})
  return Object.values(map)
}

export const getNextPage = (state: GS): number => get(state, `${basePath}.nextPage`, 1)

export const getAreMediasPending = (state: GS): boolean => get(state, `${basePath}.areMediasPending`, false)

export const getMediaFilters = (state: GS): IMediaFilters => ({
  page: getNextPage(state),
  sortField: "date",
  sortOrder: "desc",
  tagIds: [],
})
