import { getAllChapterChapterTypes } from "../graphql/allChapter.selectors"
import { IContributeView } from "../templates/contribute/contribute.props"
import { getAllModuleChapterItems, getAllModulesAsTags } from "./allModule.selectors"
import { getAllTags, getContentItem, getSeoProps, getSlug } from "./common.selectors"

const basePath = "corePageContribute"

export const getContributeSlug = (data: any) => getSlug(data, basePath)

export const getContributeSuccessSlug = (data: any) => `${getContributeSlug(data)}/danke`

export const getContributeSeo = (data: any) => getSeoProps(data, basePath)

export const getContributeTitle = (data: any) => getContentItem(data, `title`, basePath)

export const getSuccessHeadline = (data: any) => getContentItem(data, `successHeadline`, basePath)

export const getSuccessSubheadline = (data: any) => getContentItem(data, `successHeadline`, basePath)

export const getSuccessText = (data: any) => getContentItem(data, `successText`, basePath)

// prettier-ignore
export default (data: any): IContributeView => ({
  authorsContent: {
    authorTypes: [
      {
        isActive: false,
        label: "Autor*in",
        theme: "regular",
        value: "person",
      },
      {
        isActive: false,
        label: "Organisation",
        theme: "regular",
        value: "organization",
      },
    ],
    buttonAddAuthor: getContentItem(data, `formButtonAddAuthor`, basePath),
    formLabelAuthorImage: getContentItem(data, `formLabelAuthorImage`, basePath),
    formLabelAuthorImageContent: getContentItem(data, `formLabelAuthorImageContent`, basePath),
    labelAddAuthorDescription: getContentItem(data, `formLabelAddAuthorDescription`, basePath),
    labelEmail: getContentItem(data, `formLabelAuthorEmail`, basePath),
    labelFirstName: getContentItem(data, `formLabelAuthorFirstName`, basePath),
    labelLastName: getContentItem(data, `formLabelAuthorLastName`, basePath),
    labelCity: getContentItem(data, `formLabelAuthorCity`, basePath),
    labelState: getContentItem(data, `formLabelAuthorState`, basePath),
    labelNationwideCoop: getContentItem(data, `formLabelAuthorNationwideCoop`, basePath),
    labelOrganisationName: getContentItem(data, `formButtonOrganisationName`, basePath),
    labelTitle: getContentItem(data, `formLabelAuthorTitle`, basePath),
    formLabelAuthorBackground: getContentItem(data, `formLabelAuthorBackground`, basePath),
    formLabelAuthorStatement: getContentItem(data, `formLabelAuthorStatement`, basePath),
    formLabelOrganisationImage: getContentItem(data, `formLabelOrganisationImage`, basePath),
    formLabelOrganisationBackground: getContentItem(data, `formLabelOrganisationBackground`, basePath),
    formLabelOrganisationStatement: getContentItem(data, `formLabelOrganisationStatement`, basePath),
    formLabelAuthorOrganisationBackgroundInfo: getContentItem(data, `formLabelAuthorOrganisationBackgroundInfo`, basePath),
    formLabelAuthorOrganisationStatementInfo: getContentItem(data, `formLabelAuthorOrganisationStatementInfo`, basePath),
    formLabelAuthorIsPublic: getContentItem(data, `formLabelAuthorIsPublic`, basePath),
    formLabelOrganisationName: getContentItem(data, `formLabelOrganisationName`, basePath),
    formLabelOrganisationImageContent: getContentItem(data, `formLabelOrganisationImageContent`, basePath),
    formLabelOrganisationEmail: getContentItem(data, `formLabelOrganisationEmail`, basePath),
  },
  ccAttribution: getContentItem(data, `ccAttribution`, basePath),
  ccAttributionAlternative: getContentItem(data, `ccAttributionAlternative`, basePath),
  ccAttributionCurrent: getContentItem(data, `ccAttributionCurrent`, basePath),
  ccAttributionDescription: getContentItem(data, `ccAttributionDescription`, basePath),
  ccDescription: getContentItem(data, `ccDescription`, basePath),
  ccRemarksLabel: getContentItem(data, `ccRemarksLabel`, basePath),
  ccRemarksContent: getContentItem(data, `ccRemarksContent`, basePath),
  ccTitle: getContentItem(data, `ccTitle`, basePath),
  chapters: getAllChapterChapterTypes(data),
  description: getContentItem(data, `description`, basePath),
  formButtonAuthorsTab: getContentItem(data, `formButtonAuthorsTab`, basePath),
  formButtonOrganisationTab: getContentItem(data, `formButtonOrganisationTab`, basePath),
  formButtonSubmit: getContentItem(data, `formButtonSubmit`, basePath),
  formLabelAuthorTitle: getContentItem(data, `formLabelAuthorTitle`, basePath),
  formLabelAudioVideoLink: getContentItem(data, `formLabelAudioVideoLink`, basePath),
  formLabelCaptcha: getContentItem(data, `formLabelCaptcha`, basePath),
  formLabelChapterSuggest: getContentItem(data, `formLabelChapterSuggest`, basePath),
  formLabelFileUpload: getContentItem(data, `formLabelFileUpload`, basePath),
  formLabelFileUploadInfo: getContentItem(data, `formLabelFileUploadInfo`, basePath),
  formLabelMediaDescription: getContentItem(data, `formLabelMediaDescription`, basePath),
  formLabelMediaName: getContentItem(data, `formLabelMediaName`, basePath),
  formLabelMediaSubline: getContentItem(data, `formLabelMediaSubline`, basePath),
  formLabelChapter: getContentItem(data, `formLabelChapter`, basePath),
  formLabelCheckboxNewModule: getContentItem(data, `formLabelCheckboxNewModule`, basePath),
  formLabelModules: getContentItem(data, `formLabelModules`, basePath),
  formLabelOrganisationImageContent: getContentItem(data, `formLabelOrganisationImageContent`, basePath),
  seo: getContributeSeo(data),
  slugContributeSuccess: getContributeSuccessSlug(data),
  slugPrivacy: getSlug(data, `corePagePrivacy`),
  slugTerms: getSlug(data, `corePageTerms`),
  modules: getAllModulesAsTags(data, getContentItem(data, `formLabelCheckboxNewModule`, basePath)),
  licenseItems: data.allLicense.nodes ?? [],
  tags: getAllTags(data),
  title: getContributeTitle(data),
  titleAuthors: getContentItem(data, `titleAuthors`, basePath),
  titleMedia: getContentItem(data, `titleMedia`, basePath),
  titleTags: getContentItem(data, `titleTags`, basePath),
  thirdPartyTitle: getContentItem(data, `thirdPartyTitle`, basePath),
  thirdPartyContent: getContentItem(data, `thirdPartyContent`, basePath),
  thirdPartyCheckLabel: getContentItem(data, `thirdPartyCheckLabel`, basePath),
  submitNotice: getContentItem(data, `submitNotice`, basePath),
  submitNoticeHighlight: getContentItem(data, `submitNoticeHighlight`, basePath),
  __modulesWithChapters: getAllModuleChapterItems(data)
})
