import get from "lodash/get"

import { IAuthorItem } from "../state/page-authors/pageAuthors.reducer"
import { getAuthorPreview } from "./common.selectors"

const basePath = "allAuthor"

const getAllAuthorNodes = (data: any) => get(data, `${basePath}.nodes`, [])

export const getAllAuthorItems = (data: any): IAuthorItem[] =>
  getAllAuthorNodes(data).map((node: any) => getAuthorPreview(node))
