import get from "lodash/get"

import { IOfferItem } from "../components/cards/offer-card"
import { IHeroSlider, IHeroSliderItem } from "../components/hero-slider"
import { IStartViewProps } from "../templates/start/start.view.props"
import { getAllOfferItems } from "./allOffer.selectors"
import {
  getContentItem,
  getImageProps,
  getMediaItemList,
  getRelationshipItems,
  getSeoProps,
  getSlug,
} from "./common.selectors"

export const basePath = "corePageStart"
const contentPath = `${basePath}.content`
const relationshipsPath = `${basePath}.relationships`

const getModules = (data: object) => get(data, `${relationshipsPath}.modules`) || []
const getCount = (data: object) => get(data, "length") || 0

const getHeroSlider = (data: object): IHeroSlider => ({
  items: getModules(data).map(
    (module: any): IHeroSliderItem => ({
      id: get(module, "id"),
      image: getImageProps(module, "image"),
      slug: getSlug(module),
      subline: getContentItem(module, "subline"),
      title: getContentItem(module, "title"),
      authorsCount: getCount(getRelationshipItems(module, "authors")),
      mediasCount: getRelationshipItems(module, "chapters").reduce(
        (memo, chapter) => memo + getCount(getRelationshipItems(chapter, "medias")),
        0
      ),
    })
  ),
})

export const getStartSlug = (data: object) => getSlug(data, basePath)

const getTitle = (data: object) => get<object, string>(data, `${contentPath}.title`)

const getDescription = (data: object) => get<object, string>(data, `${contentPath}.description`)

const getMediasTitle = (data: object) => get<object, string>(data, `${contentPath}.mediasTitle`)

const getOffersTitle = (data: object) => get<object, string>(data, `${contentPath}.offersTitle`)

const getContributeTitle = (data: object) => get<object, string>(data, `${contentPath}.contributeTitle`)

const getContributeButton = (data: object) => get<object, string>(data, `${contentPath}.contributeButton`)

const getPlainLanguageTitle = (data: object) => get<object, string>(data, `corePagePlainLanguage.seo.title`)

const getPlainLanguageLink = (data: object) => get<object, string>(data, `corePagePlainLanguage.seo.slug`)

export default (data: any): IStartViewProps => ({
  contributeButton: getContributeButton(data),
  contributeTitle: getContributeTitle(data),
  description: getDescription(data),
  heroSlider: getHeroSlider(data),
  medias: getMediaItemList(data, basePath),
  mediasTitle: getMediasTitle(data),
  seo: getSeoProps(data, basePath),
  title: getTitle(data),
  plainLanguageTitle: getPlainLanguageTitle(data),
  plainLanguageLink: getPlainLanguageLink(data),
  initialRenderComplete: false,
  offers: getAllOfferItems(data) as IOfferItem[],
  offersTitle: getOffersTitle(data),
})
