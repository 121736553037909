import { ImportPrivacyPoliciesActionCreator } from "./privacyPolicies.types"

const BASE = `PRIVACY_POLICIES`

export const IMPORT_PRIVACY_POLICIES = `${BASE}.IMPORT_PRIVACY_POLICIES`

export const importPrivacyPolicies: ImportPrivacyPoliciesActionCreator = (allPrivacyPolicyPopup) => ({
  payload: {
    data: allPrivacyPolicyPopup,
  },
  type: IMPORT_PRIVACY_POLICIES,
})
