import { useLocation } from "@reach/router"
import { Slice } from "gatsby"
import get from "lodash/get"
import React, { FC, useEffect, useState } from "react"
import smoothscroll from "smoothscroll-polyfill"

import { getSlug } from "../../graphql/common.selectors"
import { getContributeSlug } from "../../graphql/corePageContribute.selectors"
import { isBuilding } from "../../utils/constants"
import { IconType } from "../svg-icon/svg-icon"
import Header from "./header"
import { INavLink } from "./header/navbar-link"
import { LayoutProps } from "./layout.props"
import NavbarAside from "./navbar-aside"

import "../../styles/index.scss"
import { cssContainerLayout } from "../../templates/start/start.module.scss"
import { cssLayout } from "./layout.module.scss"

const storage: Storage | null = typeof window !== "undefined" ? window.localStorage : null
const DARK_MODE_KEY = "dark_mode"

const browserHasDarkModeEnabled = (): boolean => {
  const storageValue = storage ? storage.getItem(DARK_MODE_KEY) : null
  if (storageValue) {
    return storageValue === "true"
  }

  if (isBuilding) {
    return false
  }

  return window.matchMedia("(prefers-color-scheme: dark)").matches
}

const Layout: FC<LayoutProps> = ({ data, children }) => {
  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const homeLink = getHomeLink(data)
  const mediaFormLink = getMediaFormLink(data)
  const mediaFormLinkSearch = getMediaFormLinkSearch(data)
  const navLinks = getNavLinks(data)
  const filteredNavLinks = getFilteredNavLinks(navLinks)

  const [isMainMenuVisible, setIsMainMenuVisible] = useState<boolean>(false)
  const [isSearchBarVisible, setIsSearchBarVisible] = useState<boolean>(false)
  const [isSearchBarAsideVisible, setIsSearchBarAsideVisible] = useState<boolean>(false)
  const [isDarkModeActive, setIsDarkModeActive] = useState(browserHasDarkModeEnabled)

  useEffect(() => {
    if (isDarkModeActive) {
      document.documentElement.classList.add("dark")
    } else {
      document.documentElement.classList.remove("dark")
    }

    if (storage) {
      storage.setItem(DARK_MODE_KEY, isDarkModeActive.toString())
    }
  }, [isDarkModeActive])

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible)
  }

  const toggleMainMenu = () => {
    setIsMainMenuVisible(!isMainMenuVisible)
  }

  const toggleSearchBarAside = () => {
    setIsSearchBarAsideVisible(!isSearchBarAsideVisible)
  }

  const toggleDarkMode = (ev?: MouseEvent) => {
    ev?.preventDefault()
    setIsDarkModeActive(!isDarkModeActive)
  }

  const location = useLocation()

  return location.pathname !== "/" ? (
    <div className={cssLayout}>
      <Header
        homeLink={homeLink}
        toggleSearchBar={toggleSearchBar}
        isSearchBarVisible={isSearchBarVisible}
        toggleMainMenu={toggleMainMenu}
        isMainMenuVisible={isMainMenuVisible}
        isDarkModeActive={isDarkModeActive}
        toggleDarkMode={toggleDarkMode}
        mediaFormLink={mediaFormLink}
        mediaFormLinkSearch={mediaFormLinkSearch}
        navLinks={filteredNavLinks}
      />
      <main id="main">{children}</main>
      <Slice alias="footer" />
      <Slice alias="backdrop" />
      <NavbarAside
        mediaFormLink={mediaFormLink}
        navLinks={filteredNavLinks}
        toggleSearchBarAside={toggleSearchBarAside}
        isSearchBarAsideVisible={isSearchBarAsideVisible}
        isDarkModeActive={isDarkModeActive}
        toggleDarkMode={toggleDarkMode}
      />
    </div>
  ) : (
    <>
      <div className={`${cssContainerLayout}`}>
        <Header
          homeLink={homeLink}
          toggleSearchBar={toggleSearchBar}
          isSearchBarVisible={isSearchBarVisible}
          toggleMainMenu={toggleMainMenu}
          isMainMenuVisible={isMainMenuVisible}
          mediaFormLink={mediaFormLink}
          mediaFormLinkSearch={mediaFormLinkSearch}
          navLinks={filteredNavLinks}
          isDarkModeActive={isDarkModeActive}
          toggleDarkMode={toggleDarkMode}
        />
      </div>
      <main id="main">{children}</main>
      <div className={`${cssContainerLayout}`}>
        <Slice alias="footer" />
        <Slice alias="backdrop" />
        <NavbarAside
          mediaFormLink={mediaFormLink}
          navLinks={filteredNavLinks}
          toggleSearchBarAside={toggleSearchBarAside}
          isSearchBarAsideVisible={isSearchBarAsideVisible}
          isDarkModeActive={isDarkModeActive}
          toggleDarkMode={toggleDarkMode}
        />
      </div>
    </>
  )
}

const getHomeLink = (data: any): INavLink => ({
  isBrand: true,
  label: "politischbilden.de",
  slug: getSlug(data, "corePageStart"),
})

const getTitle = (data: any, basePath: string) => get(data, `${basePath}.seo.title`)
const getType = (data: any, basePath: string) => get(data, `${basePath}.type`)

const getNavLinks = (data: any): INavLink[] => [
  {
    label: getTitle(data, "corePageMedias"),
    slug: getSlug(data, "corePageMedias"),
    type: getType(data, "corePageMedias"),
  },
  {
    label: getTitle(data, "corePageAuthors"),
    slug: getSlug(data, "corePageAuthors"),
    type: getType(data, "corePageAuthors"),
  },
  {
    label: getTitle(data, "corePageOffers"),
    slug: getSlug(data, "corePageOffers"),
    type: getType(data, "corePageOffers"),
  },
  {
    label: getTitle(data, "corePageContact"),
    slug: getSlug(data, "corePageContact"),
    type: getType(data, "corePageContact"),
  },
  {
    label: getTitle(data, "corePageAbout"),
    slug: getSlug(data, "corePageAbout"),
    type: getType(data, "corePageAbout"),
  },
  {
    label: getTitle(data, "corePagePlainLanguage"),
    slug: getSlug(data, "corePagePlainLanguage"),
    type: getType(data, "corePagePlainLanguage"),
  },
  {
    label: getTitle(data, "corePageSignLanguage"),
    slug: getSlug(data, "corePageSignLanguage"),
    type: getType(data, "corePageSignLanguage"),
  },
  {
    label: getTitle(data, "corePageAccessibility"),
    slug: getSlug(data, "corePageAccessibility"),
    type: getType(data, "corePageAccessibility"),
  },
  {
    label: getTitle(data, "corePageMediasCollection"),
    slug: getSlug(data, "corePageMediasCollection"),
    type: getType(data, "corePageMediasCollection"),
  },
]

const getFilteredNavLinks = (navLinks: INavLink[]): INavLink[] =>
  navLinks.filter((link) => link.label && link.slug && link.type)

const getMediaFormLink = (data: any): INavLink => ({
  isHighlighted: true,
  label: "Material einreichen",
  slug: getContributeSlug(data),
})

const getMediaFormLinkSearch = (data: any): INavLink => ({
  iconType: IconType.UPLOAD,
  isHighlighted: true,
  label: "Suche",
  slug: getContributeSlug(data),
})

export default Layout
