import { AnyAction, Reducer } from "redux"

import { IMPORT_PRIVACY_POLICIES } from "./privacyPolicies.actions"
import { IPrivacyPoliciesState } from "./privacyPolicies.types"

const initialState: IPrivacyPoliciesState = {
  data: [],
}

const privacyPolicies: Reducer = (state: IPrivacyPoliciesState = initialState, action: AnyAction) => {
  switch (action.type) {
    case IMPORT_PRIVACY_POLICIES:
      return {
        ...state,
        data: action.payload.data,
      }
    default:
      return state
  }
}

export default privacyPolicies
