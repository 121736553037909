import axios from "axios"

import { getApiUrlContent } from "./configs"

export const queryAPISuggestions = (query: string, setSuggestions: any) => {
  if (query && query.length > 2) {
    axios
      .get(getApiUrlContent() + "/suggest?query=" + query)
      .then((response) => {
        const data = response.data.data.suggestions

        setSuggestions(data)
      })
      .catch((e) => console.log(e))
  }
}
