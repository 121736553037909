import cn from "classnames"
import { navigate } from "gatsby"
import { noop } from "lodash"
import React, { FC, useEffect, useState } from "react"

import useDebounce from "../../hooks/use-debounce"
import { queryAPISuggestions } from "../../utils/queryAPISuggestions"
import { ISuggestion } from "../search-input/search-input.props"

import * as styles from "./search-bar-aside.module.scss"

interface ISearchBarAside {
  onClickSuggestion: () => void
}

/**
 * Attention! Basically a duplicate of SearchInput, but with different
 * classnames and search-submit-icon
 */
const SearchBarAside: FC<ISearchBarAside> = ({ onClickSuggestion = noop }) => {
  const [inputValue, setInputValue] = useState<string>("")
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([])
  const debouncedValue = useDebounce<string>(inputValue, 350)

  useEffect(() => {
    queryAPISuggestions(inputValue, setSuggestions)
  }, [debouncedValue])

  const onChangeHandler = (event: { target: { value: React.SetStateAction<string> } }) => {
    setInputValue(event.target.value)
  }

  const onClickSuggestionHandler = (ev: any) => {
    // Use navigate() to avoid re-load of page

    ev.preventDefault()
    const target = ev.target as HTMLElement
    navigate(target.getAttribute("href") || "")
    setSuggestions([])
    onClickSuggestion()
  }

  const onSubmitHandler = (ev: any) => {
    ev.preventDefault()
    navigate("/suche?query=" + encodeURI(inputValue))
    setSuggestions([])
    onClickSuggestion()
  }

  return (
    <div className="search-bar-container search-bar-input-container-aside">
      <form method="GET" action="/suche" role="search">
        <div className="search-bar-input-container">
          <div className="search-input-aside">
            <input
              className={cn("input", styles.searchInput)}
              type="text"
              placeholder="Suchbegriff eingeben"
              onChange={onChangeHandler}
            />
            <a className="search-input-button-link-aside" onClick={onSubmitHandler}>
              <i className="icon-arrow-right" />
            </a>
          </div>
        </div>
      </form>

      {suggestions.length > 0 && inputValue.length >= 3 ? (
        <div className="search-bar-input-container">
          <ul className="search-input-suggestions-aside">
            {suggestions.map((suggestion) => (
              <li key={suggestion.value}>
                <a href={"/suche?query=" + encodeURI(suggestion.value)} onClick={onClickSuggestionHandler}>
                  <i className="icon-arrow-right" />
                  {suggestion.value}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

export default SearchBarAside
