import { AnyAction, Reducer } from "redux"

import { IMediaItem } from "../../components/cards/media-card"
import * as allMediaSelectors from "../../graphql/allMedia.selectors"
import { arrToObj } from "../../utils/object-utils"
import { IMPORT_ALL_MEDIA } from "./medias.actions"
import { IMediasState } from "./medias.types"

const initialState: IMediasState = {
  allMedia: {},
}

/**
 * Returns the `allMedia` and `visibleMedia` fields, which are derived
 * from the given graphql data.
 */
const importAllMedia = (graphqlData: any) => {
  const allMediaList = allMediaSelectors.getAllMediaItems(graphqlData) // TODO: should already return a Map
  const allMedia = arrToObj<IMediaItem>(allMediaList, (media) => media.id)
  return {
    allMedia,
  }
}

const reducer: Reducer = (state: IMediasState = initialState, action: AnyAction) => {
  switch (action.type) {
    case IMPORT_ALL_MEDIA:
      return {
        ...state,
        ...importAllMedia(action.payload.graphqlData),
      }
    default:
      return state
  }
}

export default reducer
