import { SidebarActionCreator, SidebarHideActionCreator } from "./layout.props"

const BASE = "LAYOUT"
export const SHOW_SIDEBAR = `${BASE}.SHOW_SIDEBAR`
export const HIDE_SIDEBAR = `${BASE}.HIDE_SIDEBAR`
export const TOGGLE_SIDEBAR = `${BASE}.TOGGLE_SIDEBAR`

export const toggleSidebar: SidebarActionCreator = (item) => ({
  payload: {
    item,
  },
  type: TOGGLE_SIDEBAR,
})

export const showSidebar: SidebarActionCreator = (item) => ({
  payload: {
    item,
  },
  type: SHOW_SIDEBAR,
})

export const hideSidebar: SidebarHideActionCreator = () => ({
  payload: {
    item: null,
  },
  type: HIDE_SIDEBAR,
})
