import get from "lodash/get"

import { ISelectItem } from "../components/select"
import { getChapterTypeSelect } from "./common.selectors"

const basePath = "allChapter"

const getAllChapterNodes = (data: any) => get(data, `${basePath}.nodes`, [])

const getAllChapterTypeNodes = (data: any) => get(data, `allChapterType.nodes`, [])

export const getAllChapterSelectItems = (data: any): ISelectItem[] => getAllChapterNodes(data).map(getChapterTypeSelect)

export const getAllChapterSelectItemsFromChapterType = (data: any): ISelectItem[] =>
  getAllChapterTypeNodes(data).map(getChapterTypeSelect)

export const getAllChapterChapterTypes = (data: any) =>
  getAllChapterTypeNodes(data).map((data: any) => ({
    description: data?.description ?? "",
    title: data?.title ?? "",
  }))
