import { IGlobalState } from "../state.props"
import { SidebarItem } from "./layout.props"

export const selectNavbarItem = (state: IGlobalState) => state.layout.sidebarItem
export const selectIsNavbarOpen = (state: IGlobalState) => selectNavbarItem(state) === SidebarItem.NAVBAR
export const selectIsCollectionDrawerOpen = (state: IGlobalState) =>
  selectNavbarItem(state) === SidebarItem.COLLECTION_DRAWER
export const isSidebarOpen = (state: IGlobalState): boolean => state.layout.sidebarItem !== null

/**
 * @deprecated a selector should only have one parameter - the global state
 */
export const isSidebarItemOpen = (state: IGlobalState, sidebarItem: SidebarItem): boolean =>
  state.layout.sidebarItem === sidebarItem
