import { AnyAction } from "redux"

import { IOfferItem } from "../../components/cards/offer-card"
import { getAllOfferItems } from "../../graphql/allOffer.selectors"
import {
  IMPORT_ALL_OFFERS,
  SET_SELECTED_OFFER_STATE,
  SET_SELECTED_OFFER_CATEGORY_ID,
  SET_SELECTED_PERMANENT,
} from "./pageOffers.actions"

export interface IOffersPageState {
  offers: IOfferItem[]
  selectedOfferCategoryId: string | null
  selectedOfferState: string | null
  selectedIsPermanent: boolean
}

export const initialState: IOffersPageState = {
  offers: [],
  selectedOfferCategoryId: null,
  selectedOfferState: null,
  selectedIsPermanent: false,
}

export default (state: IOffersPageState = initialState, action: AnyAction): IOffersPageState => {
  switch (action.type) {
    case IMPORT_ALL_OFFERS:
      return {
        ...state,
        offers: getAllOfferItems(action.payload.graphqlData) as IOfferItem[],
      }
    case SET_SELECTED_OFFER_CATEGORY_ID:
      return {
        ...state,
        selectedOfferCategoryId: action.payload.offerCategoryId,
      }
    case SET_SELECTED_OFFER_STATE:
      return {
        ...state,
        selectedOfferState: action.payload.offerState,
      }
    case SET_SELECTED_PERMANENT:
      return {
        ...state,
        selectedIsPermanent: action.payload.isSelected,
      }
  }
  return state
}
