import { ImportAllMediaActionCreator } from "../page-medias/pageMedias.types"

const BASE = `PAGE_AUTHORSz`
export const IMPORT_ALL_AUTHORS = `${BASE}.IMPORT_ALL_AUTHORS`
export const SET_SELECTED_AUTHOR_TYPE = `${BASE}.SET_SELECTED_AUTHOR_TYPE`
export const SET_SELECTED_AUTHOR_STATE = `${BASE}.SET_SELECTED_AUTHOR_STATE`
export const SET_SELECTED_NATIONWIDE_COOP = `${BASE}.SET_SELECTED_NATIONWIDE_COOP`

export const importAllAuthors: ImportAllMediaActionCreator = (graphqlData: any) => ({
  payload: {
    graphqlData,
  },
  type: IMPORT_ALL_AUTHORS,
})

export const setSelectedAuthorType = (authorType: string) => ({
  payload: {
    authorType,
  },
  type: SET_SELECTED_AUTHOR_TYPE,
})

export const setSelectedAuthorState = (authorState: string | null) => ({
  payload: {
    authorState,
  },
  type: SET_SELECTED_AUTHOR_STATE,
})

export const setSelectedNationwideCoop = (selectedNationwideCoop: boolean) => ({
  payload: {
    selectedNationwideCoop,
  },
  type: SET_SELECTED_NATIONWIDE_COOP,
})
