module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"politischbilden.de","short_name":"politischbilden.de","start_url":"/","background_color":"#017484","theme_color":"#017484","display":"minimal-ui","icon":"src/images/favicon-256.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"68fc31febbb4b6205302098515719daf"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/www/users/adboer/git/adb-virtual-academy/gatsby/src/components/layout"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://stats.politischbilden.de","siteUrl":"https://politischbilden.de","disableCookies":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
