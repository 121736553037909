import cn from "classnames"
import React, { FC, useEffect, useRef } from "react"

import ButtonSearch from "../../button-search"
import { IconType } from "../../svg-icon/svg-icon"
import NavLink, { INavLink } from "../header/navbar-link"
import NavbarToggleDesktopView from "../header/navbar-toggle-desktop"

import { cssContainerLayout } from "../../../templates/start/start.module.scss"
import * as styles from "./navbar-menu.module.scss"

interface INavbarMenu {
  mediaFormLinkSearch: INavLink
  mediaFormLink: INavLink
  navLinks: INavLink[]
  toggleSearchBar(): void
  toggleMainMenu(): void
  isMainMenuVisible: boolean
}

const NavbarMenu: FC<INavbarMenu> = ({
  navLinks,
  toggleSearchBar,
  toggleMainMenu,
  isMainMenuVisible,
  mediaFormLink,
}) => {
  const materialsCollectionNavLink = navLinks.find((navLink) => navLink.type === "media_collection")
  const navLinksWithMediaFormLink = [...navLinks, mediaFormLink]
  const filteredNavLinks = navLinksWithMediaFormLink.filter(
    (navLink) =>
      navLink.type !== "sign_language" &&
      navLink.type !== "plain_language" &&
      navLink.type !== "accessibility" &&
      navLink.type !== "media_collection"
  )

  const navLinkListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!navLinkListRef.current || !isMainMenuVisible) {
      return
    }

    const firstNavLink = navLinkListRef.current.querySelector("a")
    firstNavLink?.focus()
  }, [isMainMenuVisible])

  return (
    <div className={cn("navbar-menu", styles.component)}>
      <div>
        <div className={cn("navbar-end", styles.navbarEnd)}>
          {materialsCollectionNavLink && (
            <NavLink
              label={materialsCollectionNavLink.label}
              slug={materialsCollectionNavLink.slug}
              iconType={IconType.X}
              isMaterialsLink={true}
            />
          )}
          <ButtonSearch className="button-search" toggleSearchBar={toggleSearchBar} />
          <NavbarToggleDesktopView isMainMenuVisible={isMainMenuVisible} toggleMainMenu={toggleMainMenu} />
        </div>

        <div className={styles.mainMenuContainer}>
          <div className={isMainMenuVisible ? styles.open : styles.closed}>
            <div ref={navLinkListRef} className={cn(cssContainerLayout, styles.mainMenuItem)}>
              {filteredNavLinks.map((navLink, idx) => (
                <NavLink
                  key={idx}
                  {...navLink}
                  onClick={toggleMainMenu}
                  onBlur={() => {
                    // After leaving last element, focus close button
                    if (idx + 1 === filteredNavLinks.length) {
                      document.getElementById("toggle-main-menu")?.focus()
                    }
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavbarMenu
