import { AnyAction } from "redux"

import { IMediaItem } from "../../components/cards/media-card"

// Actions

const BASE = `MEDIA_COLLECTION`
export const ADD_MEDIA = `${BASE}.ADD_MEDIA`
export const DOWNLOAD_ALL_MEDIA = `${BASE}.DOWNLOAD_ALL_MEDIA`
export const DOWNLOAD_MEDIA = `${BASE}.DOWNLOAD_MEDIA`
export const REMOVE_MEDIA = `${BASE}.REMOVE_MEDIA`
export const FLUSH_MEDIA = `${BASE}.FLUSH_MEDIA`

// Action creator interfaces

interface IMediaAction extends AnyAction {
  payload: {
    media: IMediaItem
  }
}

// Action creator types

export type MediaActionCreator = (media: IMediaItem) => IMediaAction

export type AnyActionCreator = () => AnyAction

// Action creator implementations

export const addMedia: MediaActionCreator = (media) => ({
  payload: {
    media,
  },
  type: ADD_MEDIA,
})

export const downloadAllMedia: AnyActionCreator = () => ({
  type: DOWNLOAD_ALL_MEDIA,
})

export const downloadMedia: MediaActionCreator = (media) => ({
  payload: {
    media,
  },
  type: DOWNLOAD_MEDIA,
})

export const removeMedia: MediaActionCreator = (media) => ({
  payload: {
    media,
  },
  type: REMOVE_MEDIA,
})

export const flushMedia: AnyActionCreator = () => ({
  type: FLUSH_MEDIA,
})

// Store medias locally as requested in #1448

// Fixes "window"/"localStorage" is not defined
// @see https://github.com/gatsbyjs/gatsby/issues/309
export const windowGlobal = typeof window !== "undefined" && window

export const getLocalstorageMedias = (): IMediaItem[] =>
  JSON.parse(windowGlobal ? (localStorage.getItem("medias") as string) : "[]") || []

export const addLocalstorageMedia = (media: IMediaItem) =>
  (windowGlobal as any).localStorage.setItem("medias", JSON.stringify([...getLocalstorageMedias(), media]))

export const removeLocalstorageMedia = (media: IMediaItem) => {
  const medias = getLocalstorageMedias()
  ;(windowGlobal as any).localStorage.setItem(
    "medias",
    JSON.stringify(medias.filter((_media) => _media.id !== media.id))
  )
}

export const flushLocalstorageMedia = () => (windowGlobal as any).localStorage.removeItem("medias")
