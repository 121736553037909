import { connect } from "react-redux"

import { toggleSidebar } from "../../../state/layout/layout.actions"
import { SidebarItem } from "../../../state/layout/layout.props"
import { selectMediaCollectionCount } from "../../../state/media-collection/media-collection.selectors"
import { IGlobalState } from "../../../state/state.props"
import MediaBadgeView from "./media-badge.view"

const mapStateToProps = (state: IGlobalState) => ({
  count: selectMediaCollectionCount(state),
})

const dispatchToProps = {
  onClick: () => toggleSidebar(SidebarItem.COLLECTION_DRAWER),
}

const MediaBadge = connect(mapStateToProps, dispatchToProps)(MediaBadgeView)

export default MediaBadge
