import get from "lodash/get"

import { IOfferItem } from "../components/cards/offer-card"
import { getOfferPreview } from "./common.selectors"

const basePath = "allOffer"

export const getAllOfferNodes = (data: any): any[] => get(data, `${basePath}.nodes`, [])

export const getAllOfferItems = (data: any): Partial<IOfferItem>[] =>
  getAllOfferNodes(data).map((node: any) => getOfferPreview(node))
