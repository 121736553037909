import get from "lodash/get"

import { IMediaItem } from "../components/cards/media-card"
import { IModuleItem } from "../components/cards/module-card"
import { IOfferItem } from "../components/cards/offer-card"
import { IImageProps } from "../components/image/image.props"
import { ISelectItem } from "../components/select"
import { ISEOProps } from "../components/seo/seo.props"
import { ITag } from "../components/tag-cloud"
import { IAuthorItem } from "../state/page-authors/pageAuthors.reducer"
import { IChapter } from "../templates/module-details/module-details.props"
import { IStandardViewProps } from "../templates/standard/standard.props"

// Generic ------------------------------------------------------------------------

export const getItem = (data: any, relPath: string, basePath?: string) =>
  get(data, basePath ? `${basePath}.${relPath}` : relPath)

export const getId = (data: any, basePath?: string): string => getItem(data, "id", basePath)

export const getRelationshipItems = (data: any, endpoint: string, basePath?: string): any[] =>
  getItem(data, `relationships.${endpoint}`, basePath) || []

export const getContentItem = (data: any, endpoint: string, basePath?: string) =>
  getItem(data, `content.${endpoint}`, basePath)

export const getSeoItem = (data: any, endpoint: string, basePath?: string) => getItem(data, `seo.${endpoint}`, basePath)

export const getSeoProps = (data: any, basePath?: string): ISEOProps => ({
  metaTags: getSeoItem(data, `metaTags`, basePath),
  title: getSeoItem(data, `title`, basePath),
  slug: getSeoItem(data, `slug`, basePath),
  ogImage: getSeoItem(data, `ogImage.image.urlOg`, basePath),
})

export const getSiteUrl = (data: any) => get(data, "site.siteMetadata.siteUrl")

export const getVideoUrl = (data: any, basePath?: string): string => getContentItem(data, `videoUrl`, basePath)

// Author ------------------------------------------------------------------------

export const getAuthorPreview = (data: any): IAuthorItem => ({
  city: getContentItem(data, "city"),
  id: getId(data),
  image: getImageProps(data, "image"),
  jobTitle: getContentItem(data, "jobTitle"),
  nationwideCooperation: getContentItem(data, "nationwideCooperation"),
  state: getContentItem(data, "state"),
  slug: getSlug(data),
  title: getContentItem(data, "displayName"),
  type: getContentItem(data, "type"),
})

export const getAuthorPreviewList = (data: any, basePath?: string): IAuthorItem[] =>
  getRelationshipItems(data, "authors", basePath).map(getAuthorPreview)

const getAuthorSelectItem = (data: any): ISelectItem => ({
  id: getId(data),
  label: getContentItem(data, "displayName"),
  value: getId(data),
})

export const getAuthorSelectItemList = (data: any, basePath?: string): ISelectItem[] =>
  getRelationshipItems(data, "authors", basePath).map(getAuthorSelectItem)

// Chapters ---------------------------------------------------------------------

export const getChapterTypeSelect = (data: any): ISelectItem => ({
  id: get(data, "title"),
  label: get(data, "title"),
  value: get(data, "title"),
})

export const getChapterTypeIDSelect = (data: any): ISelectItem => ({
  id: getId(data),
  label: get(data, "title"),
  value: getId(data),
})

export const getRelatedChapterTypeItems = (data: any, basePath?: string): ISelectItem[] =>
  getRelationshipItems(data, "chapterTypes", basePath).map(getChapterTypeIDSelect)

export const getAllChapterTypeItems = (data: any): ISelectItem[] =>
  get(data, "allChapterType.nodes", []).map(getChapterTypeSelect)

export const getRelatedChapters = (data: any, basePath?: string): IChapter[] =>
  getRelationshipItems(data, `chapters`, basePath).map((chapter: any) => ({
    chapterTypes: getRelationshipItems(chapter, "chapterType"),
    description: get(chapter, "description"),
    id: getId(chapter),
    mediaItems: getMediaItemList(chapter),
    title: get(chapter, "relationships.chapterType.title"),
  }))

// Image ------------------------------------------------------------------------

export const getImageProps = (data: any, endpoint: string, basePath?: string): IImageProps => {
  const img = getContentItem(data, endpoint, basePath)
  return (
    img && {
      alt: get(img, "alt"),
      imageFluid: get(img, "local.childImageSharp.gatsbyImageData"),
      license: get(img, "license", ""),
    }
  )
}

// Media ------------------------------------------------------------------------

export const getMediaTitle = (data: any, basePath?: string): string => getContentItem(data, `title`, basePath)

export const getMediaId = (data: any, basePath?: string): string => getId(data, basePath)

export const getMediaItem = (data: any, basePath?: string): IMediaItem => ({
  description: getContentItem(data, "descriptionShort", basePath),
  id: getId(data, basePath),
  image: getImageProps(data, "image", basePath),
  slug: getSlug(data, basePath),
  title: getContentItem(data, "title", basePath),
  documents: getRelationshipItems(data, "documents", basePath),
})

export const getMediaItemList = (data: any, basePath?: string): IMediaItem[] =>
  getRelationshipItems(data, "medias", basePath).map((item: any) => getMediaItem(item))

// Module ------------------------------------------------------------------------

export const getModulePreview = (data: any): IModuleItem => ({
  description: getContentItem(data, "descriptionShort"),
  id: get(data, "id"),
  image: getImageProps(data, "image"),
  slug: getSlug(data),
  subtitle: getContentItem(data, "subline"),
  title: getContentItem(data, "title"),
})

export const getRelatedModulePreviewList = (data: any, basePath?: string): IModuleItem[] =>
  getRelationshipItems(data, "modules", basePath).map(getModulePreview)

const getModuleSelect = (data: any): ISelectItem => ({
  id: getId(data),
  label: getContentItem(data, "title"),
  value: getId(data),
})

export const getRelatedModuleSelectList = (data: any, basePath?: string): ISelectItem[] =>
  getRelationshipItems(data, "modules", basePath).map(getModuleSelect)

export const getAllModuleSelectList = (data: any): ISelectItem[] =>
  get(data, "allModule.nodes", []).map(getModuleSelect)

// Seo ------------------------------------------------------------------------

export const getSlug = (data: any, basePath?: string) => getSeoItem(data, "slug", basePath)

// Tags ------------------------------------------------------------------------

const addSelectionField = (tags: any[]) =>
  tags.map((tag) => ({
    ...tag,
    isSelected: false,
  }))

export const getRelatedTags = (data: any, basePath?: string): ITag[] =>
  addSelectionField(getRelationshipItems(data, `tags`, basePath))

export const getAllTags = (data: any): ITag[] => addSelectionField(get(data, "allTag.nodes"))

// Standard View ----------------------------------------------------------------

export const getStandardViewProps = (data: any, basePath: string): IStandardViewProps => ({
  description: getContentItem(data, `description`, basePath),
  seo: getSeoProps(data, basePath),
  title: getContentItem(data, `title`, basePath),
  logo1: getImageProps(data, `logo1`, basePath),
  logo2: getImageProps(data, `logo2`, basePath),
  subPages: getContentItem(data, `subPages`, basePath),
})

// Offer ------------------------------------------------------------------------

export const getOfferPreview = (data: any): Partial<IOfferItem> => ({
  id: getId(data),
  title: getContentItem(data, "title"),
  image: getImageProps(data, "image"),
  slug: getSlug(data),
  descriptionShort: getContentItem(data, `descriptionShort`),
  city: getContentItem(data, "city"),
  state: getContentItem(data, "state"),
  // authors: getRelationshipItems(data, "authors"),
  categories: getRelationshipItems(data, "categories"),
  isPermanent: getItem(data, "isPermanent"),
})

export const getOffers = (data: any, basePath?: string): Partial<IOfferItem>[] =>
  getRelationshipItems(data, "offers", basePath).map((node: any) => getOfferPreview(node))
