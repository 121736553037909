import { graphql, useStaticQuery } from "gatsby"
import React, { FC, ReactNode } from "react"
import { useSelector } from "react-redux"

import { getAllPrivacyPolicyPopupNodes } from "../../graphql/allPrivacyPolicyPopup.selectors"
import { useImportPrivacyPolicies } from "../../state/privacy-policies/privacyPolicies.hooks"
import { getPrivacyPolicies } from "../../state/privacy-policies/privacyPolicies.selectors"
import { LayoutData } from "./layout.props"
import Layout from "./layout.view"

interface LayoutContainerProps {
  children: ReactNode
}

const LayoutContainer: FC<LayoutContainerProps> = ({ children }) => {
  const data = useStaticQuery<LayoutData>(graphql`
    query {
      corePageStart {
        seo {
          title
          slug
        }
        type
      }
      corePageAuthors {
        seo {
          title
          slug
        }
        type
      }
      corePageContact {
        seo {
          title
          slug
        }
        type
      }
      corePagePlainLanguage {
        seo {
          title
          slug
        }
        type
      }
      corePageSignLanguage {
        seo {
          title
          slug
        }
        type
      }
      corePageMedias {
        seo {
          title
          slug
        }
        type
      }
      corePageModules {
        seo {
          title
          slug
        }
        type
      }
      corePageContribute {
        seo {
          title
          slug
        }
      }
      allPrivacyPolicyPopup {
        nodes {
          type
          text
          checkboxText
          buttonText
        }
      }
      corePageAccessibility {
        seo {
          title
          slug
        }
        type
      }
      corePageAbout {
        seo {
          title
          slug
        }
        type
      }
      corePageMediasCollection {
        seo {
          title
          slug
        }
        type
      }
      corePageOffers {
        seo {
          title
          slug
        }
        type
      }
    }
  `)

  const arePrivacyPoliciesImported = useSelector(getPrivacyPolicies)

  // Once per page load import all privacy-policy-popups into state
  useImportPrivacyPolicies(getAllPrivacyPolicyPopupNodes(data), arePrivacyPoliciesImported.length >= 1)

  return <Layout data={data}>{children}</Layout>
}

export default LayoutContainer
