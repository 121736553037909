import { AnyAction, Reducer } from "redux"

import { IMediaItem } from "../../components/cards/media-card"
import { ADD_MEDIA, FLUSH_MEDIA, REMOVE_MEDIA, getLocalstorageMedias } from "./media-collection.actions"

export interface IMediaCollectionState {
  medias: IMediaItem[]
}

const initialState = {
  medias: getLocalstorageMedias(),
}

const mediaCollectionReducer: Reducer<IMediaCollectionState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEDIA:
      return {
        ...state,
        medias: [action.payload.media, ...state.medias],
      }
    case REMOVE_MEDIA:
      return {
        ...state,
        medias: state.medias.filter((media) => media.id !== action.payload.media.id),
      }
    case FLUSH_MEDIA:
      return {
        ...state,
        medias: [],
      }
  }
  return state
}

export default mediaCollectionReducer
