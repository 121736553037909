import { ContentType } from "../components/content-item/content-item.props"
import { IPrivacyPolicyPopup } from "../components/privacy-policy-popup/privacy-policy-popup.props"

export const getAllPrivacyPolicyPopupNodes = (data: any) => data?.allPrivacyPolicyPopup?.nodes ?? []

export const getPrivacyPolicyPopupItem = (
  privacyPolicyPopups: IPrivacyPolicyPopup[],
  videoUrl: string
): IPrivacyPolicyPopup | undefined => privacyPolicyPopups.find(filterByVideoProvider(videoUrl))

export const getVideoProviderFromString = (videoUrl: string): ContentType | undefined => {
  if (videoUrl.indexOf("youtube") >= 0) {
    return "youtube"
  }
  if (videoUrl.indexOf("vimeo") >= 0) {
    return "vimeo"
  }
  if (videoUrl.indexOf("soundcloud") >= 0) {
    return "soundcloud"
  }
  if (videoUrl.indexOf("mixcloud") >= 0) {
    return "mixcloud"
  }
  if (videoUrl.indexOf("spotify") >= 0) {
    return "spotify"
  }
  return undefined
}

const filterByVideoProvider =
  (videoUrl: string): any =>
  (item: IPrivacyPolicyPopup): boolean =>
    getVideoProviderFromString(videoUrl) === item.type
