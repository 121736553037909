import axios from "axios"
import get from "lodash/get"

import { getApiUrlContent } from "../utils/configs"
import { IFetchMediaResult, IMediaFilters } from "./medias.props"

export const loadMedias = async (filters: IMediaFilters, isLoadMore: boolean): Promise<IFetchMediaResult> => {
  const apiUrl = getApiUrlContent()
  const response = await axios.get(`${apiUrl}/medias-lazy`, {
    params: {
      author: filters.authorId,
      "chapter-type": filters.chapterTypeId,
      module: filters.moduleId,
      order: filters.sortOrder,
      page: filters.page,
      sort: filters.sortField,
      tags: filters.tagIds,
    },
  })

  return {
    isLoadMore,
    mediaIds: get(response, "data.data", []),
    nextPage: get(response, "data.pagination.next", 0),
  }
}
