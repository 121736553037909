import { AnyAction, Reducer } from "redux"

import { IMediaItem } from "../../components/cards"
import { getMediaItem } from "../../graphql/common.selectors"
import { windowGlobal } from "../media-collection/media-collection.actions"
import {
  ADD_SAVED_MEDIA_COLLECTION,
  SET_SELECTED_MEDIA_COLLECTION,
  IMPORT_MEDIA,
  getSavedMediaCollectionFromCookie,
} from "./saved-media-collections.actions"
import { ISavedMediaCollectionItem } from "./saved-media-collections.types"

export interface ISavedMediaCollectionsState {
  collections: ISavedMediaCollectionItem[]
  selectedCollection: ISavedMediaCollectionItem | null
  allMedias: IMediaItem[]
}

const initialState: ISavedMediaCollectionsState = {
  collections: windowGlobal ? getSavedMediaCollectionFromCookie() : [],
  selectedCollection: null,
  allMedias: [],
}

const savedMediaCollectionsReducer: Reducer<ISavedMediaCollectionsState, AnyAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ADD_SAVED_MEDIA_COLLECTION:
      return {
        ...state,
        collections: [...state.collections, action.payload.collection],
      }
    case SET_SELECTED_MEDIA_COLLECTION:
      return {
        ...state,
        selectedCollection: action.payload.collection,
      }
    case IMPORT_MEDIA:
      return {
        ...state,
        allMedias: action.payload.graphqlData.allMedia.nodes.map((item: any) => getMediaItem(item)),
      }
    default:
      return state
  }
}

export default savedMediaCollectionsReducer
