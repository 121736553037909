// extracted by mini-css-extract-plugin
export var columns = "navbar-aside-module--columns--47d72";
export var component = "navbar-aside-module--component--942a2";
export var content = "navbar-aside-module--content--d5eb8";
export var dark = "navbar-aside-module--dark--f107d";
export var enter = "navbar-aside-module--enter--39400";
export var enterActive = "navbar-aside-module--enterActive--49ac5";
export var exit = "navbar-aside-module--exit--75f21";
export var exitActive = "navbar-aside-module--exitActive--1580d";
export var metaMenu = "navbar-aside-module--metaMenu--98457";
export var navbarEnd = "navbar-aside-module--navbar-end--4da40";