import React, { FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import Logo from "../../../images/icons/logo-2.inline.svg"
import { toggleSidebar } from "../../../state/layout/layout.actions"
import { SidebarItem } from "../../../state/layout/layout.props"
import { selectIsNavbarOpen } from "../../../state/layout/layout.selectors"
import MediaBadge from "../../badge/media"
import Link from "../../link"
import SearchBarMenu from "../../search-bar-menu/search-bar-menu.view"
import NavbarMenu from "../navbar-menu"
import NavLink, { INavLink } from "./navbar-link"
import NavbarToggle from "./navbar-toggle-aside"

import { cssHeader, cssLogo, cssNavbar, cssNavbarBrand, cssSkipToContent, newLinks } from "./header.module.scss"

interface HeaderViewProps {
  homeLink: INavLink
  mediaFormLink: INavLink
  mediaFormLinkSearch: INavLink
  navLinks: INavLink[]
  toggleSearchBar(): void
  isSearchBarVisible: boolean
  toggleMainMenu(): void
  isMainMenuVisible: boolean
  isDarkModeActive: boolean
  toggleDarkMode(): void
}

const Header: FC<HeaderViewProps> = ({
  homeLink,
  toggleSearchBar,
  isSearchBarVisible,
  toggleMainMenu,
  isMainMenuVisible,
  isDarkModeActive,
  toggleDarkMode,
  mediaFormLink,
  ...rest
}) => {
  const dispatch = useDispatch()
  const isOpen = useSelector(selectIsNavbarOpen)
  const mediaCollectionLink: any = rest.navLinks.find((navLink) => navLink.type === "media_collection")

  useEffect(() => {
    // Close menu when escape is pressed
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isMainMenuVisible) {
        toggleMainMenu()
        document.getElementById("toggle-main-menu")?.focus()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [isMainMenuVisible])

  return (
    <header className={cssHeader}>
      <a className={cssSkipToContent} href="#main" tabIndex={0}>
        {"Zum Inhalt springen"}
      </a>
      <nav className={`navbar ${cssNavbar}`} aria-label="main navigation">
        <ul className={`new-links ${newLinks}`}>
          <li>
            <NavLink
              slug="#"
              label={isDarkModeActive ? "Heller Modus" : "Dunkler Modus"}
              isBrand={false}
              onClick={toggleDarkMode}
            />
          </li>
          {rest.navLinks.map((navLink, idx) =>
            navLink.type === "sign_language" || navLink.type === "plain_language" ? (
              <li key={idx}>
                <NavLink key={idx} {...navLink} />
              </li>
            ) : null
          )}
        </ul>
        <div className={"old-links"}>
          <div className={`navbar-brand is-size-4 ${cssNavbarBrand}`}>
            <Link to={homeLink.slug} className={cssLogo} tabIndex={0}>
              <span className="sr-only">{"politischbilden.de"}</span>
              <Logo />
            </Link>
            {mediaCollectionLink.slug ? <MediaBadge collectionLink={mediaCollectionLink.slug} /> : null}
            <NavbarToggle isOpen={isOpen} onToggle={() => dispatch(toggleSidebar(SidebarItem.NAVBAR))} />
          </div>
          <NavbarMenu
            toggleSearchBar={toggleSearchBar}
            toggleMainMenu={toggleMainMenu}
            isMainMenuVisible={isMainMenuVisible}
            mediaFormLink={mediaFormLink}
            {...rest}
          />
        </div>
      </nav>
      <SearchBarMenu isSearchBarVisible={isSearchBarVisible} toggleSearchBar={toggleSearchBar} />
    </header>
  )
}

export default Header
