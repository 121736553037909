import { AnyAction } from "redux"

export enum SidebarItem {
  COLLECTION_DRAWER = "COLLECTION_DRAWER",
  NAVBAR = "NAVBAR",
}

export interface ILayoutState {
  sidebarItem: SidebarItem | null
}

export interface ISidebarAction extends AnyAction {
  payload: {
    item: SidebarItem | null
  }
}

export type SidebarActionCreator = (item: SidebarItem) => ISidebarAction
export type SidebarHideActionCreator = () => ISidebarAction
