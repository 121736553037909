import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

import { IGlobalState as GS } from "../state.props"
import { MediaRecord } from "./medias.types"

const BASE_PATH = "medias"

export const getAllMediaItems = (state: GS): MediaRecord => get(state, `${BASE_PATH}.allMedia`, {})

export const areMediasImported = (state: GS): boolean => !isEmpty(getAllMediaItems(state))
