import { composeWithDevTools } from "@redux-devtools/extension"
import { applyMiddleware, combineReducers, createStore } from "redux"
import thunk from "redux-thunk"

import { Environment, getEnvironment } from "../utils/configs"
import layout from "./layout/layout.reducer"
import mediaCollection from "./media-collection/media-collection.reducer"
import medias from "./medias/medias.reducer"
import pageAuthors from "./page-authors/pageAuthors.reducer"
import pageMedias from "./page-medias/pageMedias.reducer"
import pageOffers from "./page-offers/pageOffers.reducer"
import pageStart from "./page-start/pageStart.reducer"
import privacyPolicies from "./privacy-policies/privacyPolicies.reducer"
import savedMediaCollections from "./saved-media-collections/saved-media-collections.reducer"

const reducers = combineReducers({
  layout,
  mediaCollection,
  medias,
  pageAuthors,
  pageMedias,
  pageStart,
  pageOffers,
  privacyPolicies,
  savedMediaCollections,
})

const middleware = [thunk]

export const store =
  getEnvironment() === Environment.Production
    ? createStore(reducers, undefined, applyMiddleware(...middleware))
    : createStore(reducers, undefined, composeWithDevTools(applyMiddleware(...middleware)))

export default () => store
