export const statesOfGermany = [
  "Baden-Württemberg",
  "Bayern",
  "Berlin",
  "Brandenburg",
  "Bremen",
  "Hamburg",
  "Hessen",
  "Mecklenburg-Vorpommern",
  "Niedersachsen",
  "Nordrhein-Westfalen",
  "Rheinland-Pfalz",
  "Saarland",
  "Sachsen",
  "Sachsen-Anhalt",
  "Schleswig-Holstein",
  "Thüringen",
  "Überregional",
]

export const statesOfGermanySelectOptions = statesOfGermany.map((stateName) => ({
  id: stateName,
  value: stateName,
  label: stateName,
}))

export const isBuilding: boolean = typeof window == "undefined";
