import { getApiUrlContent } from "../../utils/configs"
import { ImportVisibleMediaActionCreator } from "../page-start/pageState.types"
import { getMediaById } from "./saved-media-collections.selectors"
import { ISavedMediaCollectionItem } from "./saved-media-collections.types"

export const ADD_SAVED_MEDIA_COLLECTION = `ADD_SAVED_MEDIA_COLLECTION`
export const SET_SELECTED_MEDIA_COLLECTION = `SET_SELECTED_MEDIA_COLLECTION`
export const FETCH_SELECTED_MEDIA_COLLECTION_ERROR = `FETCH_SELECTED_MEDIA_COLLECTION_ERROR`
export const IMPORT_MEDIA = `IMPORT_MEDIA`

const getCookie = (name: string) => document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))?.at(2)

export const addSavedMediaCollection = (collection: ISavedMediaCollectionItem) => ({
  payload: {
    collection,
  },
  type: ADD_SAVED_MEDIA_COLLECTION,
})

export const setSelectedMediaCollection = (collection: ISavedMediaCollectionItem | null) => ({
  payload: {
    collection,
  },
  type: SET_SELECTED_MEDIA_COLLECTION,
})

export const importSavedMediaCollectionMedia: ImportVisibleMediaActionCreator = (allMedia, graphqlData) => ({
  payload: {
    allMedia,
    graphqlData,
  },
  type: IMPORT_MEDIA,
})

// Fetch utilities & cookie mutation helpers
const MEDIA_COLLECTION_COOKIE = "savedMediaCollection"

const setCookie = (name: string, value: string, expiresAt: string) => {
  document.cookie = `${name}=${value};expires=${expiresAt};path=/`
}

export const getSavedMediaCollectionFromCookie = () => {
  const cookie = getCookie(MEDIA_COLLECTION_COOKIE)
  return cookie ? JSON.parse(cookie) : []
}

export const addSavedMediaCollectionToCookie = (collection: ISavedMediaCollectionItem) => {
  const expiresAt = new Date(collection.expiresAt)
  const cookie = getCookie(MEDIA_COLLECTION_COOKIE)
  const collections = cookie ? JSON.parse(cookie) : []
  collections.push(collection)
  setCookie(MEDIA_COLLECTION_COOKIE, JSON.stringify(collections), expiresAt.toUTCString())
}

export const fetchSavedMediaCollection = (id: string) => async (dispatch, getState) => {
  const apiUrl = getApiUrlContent()
  const response = await fetch(`${apiUrl}/media-collections/${id}`)

  if (!response.ok) {
    return Promise.reject()
  }

  const { data } = await response.json()

  data.fullMedias = data.medias.map((media: string) => getMediaById(getState(), media))

  dispatch(setSelectedMediaCollection(data))

  return true
}

export const postMediaCollection = async (
  title: string,
  name: string,
  medias: string[]
): Promise<ISavedMediaCollectionItem> => {
  const apiUrl = getApiUrlContent()
  const response = await fetch(`${apiUrl}/media-collections`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify({
      title,
      name,
      medias,
    }),
  })

  if (!response.ok) {
    return Promise.reject()
  }

  const { data } = await response.json()
  return data
}
