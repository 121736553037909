import compose from "lodash/fp/compose"
import { AnyAction, Reducer } from "redux"

import { IMediaItem } from "../../components/cards/media-card"
import { getMediaItemList } from "../../graphql/common.selectors"
import { basePath } from "../../graphql/corePageStart.selectors"
import { MediaRecord } from "../medias/medias.types"
import {
  FETCH_MEDIAS_ERROR,
  FETCH_MEDIAS_PENDING,
  FETCH_MEDIAS_SUCCESS,
  IMPORT_VISIBLE_MEDIA,
} from "./pageStart.actions"
import { IImportVisibleMediaAction, IStartPageState } from "./pageState.types"

const initialState: IStartPageState = {
  areMediasPending: false,
  nextPage: 1,
  visibleMedias: {},
}

const getInitialMedias = (data: object) => getMediaItemList(data, basePath)

const extractMediaIds = (items: IMediaItem[]) => items.map((item) => item.id)

export const mapIdsToMediaItems =
  (allMedias: MediaRecord) =>
  (ids: string[]): MediaRecord =>
    ids.reduce((map, id) => {
      const item = allMedias[id]
      return item ? { ...map, [id]: item } : map // Don't safe undefined values from the list, in case the allMedia Map doesn't include one of the initial media items for some reason.
    }, {})

const importVisibleMedia = (action: IImportVisibleMediaAction) =>
  compose(mapIdsToMediaItems(action.payload.allMedia), extractMediaIds, getInitialMedias)(action.payload.graphqlData)

const pageStart: Reducer = (state: IStartPageState = initialState, action: AnyAction) => {
  switch (action.type) {
    case FETCH_MEDIAS_PENDING:
      return {
        ...state,
        areMediasPending: true,
      }
    case FETCH_MEDIAS_SUCCESS:
      return {
        ...state,
        areMediasPending: false,
        nextPage: action.payload.nextPage || 0,
        visibleMedias: {
          ...state.visibleMedias,
          ...action.payload.mediaItems,
        },
      }
    case FETCH_MEDIAS_ERROR:
      return {
        ...state,
        areMediasPending: false,
      }
    case IMPORT_VISIBLE_MEDIA:
      return {
        ...state,
        visibleMedias: importVisibleMedia(action as IImportVisibleMediaAction),
      }
    default:
      return state
  }
}

export default pageStart
